import { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  TextArea,
  Image,
  Segment,
  Header,
  Checkbox,
  Dropdown,
} from "semantic-ui-react";
import { configureProductProductshowcase } from "services/access/product";
import { utils } from "services/api-data/src/App";
import { useDispatch, useSelector } from "react-redux";

export function ProductShowcaseSettings() {
  const [preview, setPreview] = useState(null);
  const [message, setMessage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [highlighted, setHighlighted] = useState(false);
  const [relatedLink, setRelatedLink] = useState("");
  const [existingData, setExistingData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [mode, setMode] = useState(null); // 'create' or 'update'
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const { initialize, data, action } = configureProductProductshowcase(
    utils,
    dispatch,
    state
  );

  useEffect(() => {
    action.fetchProductshowcaseList();
  }, [data?.productshowcase_create_item, data?.productshowcase_delete_item]);

  useEffect(() => {
    if (data?.productshowcase_fetch_list) {
      setExistingData(data.productshowcase_fetch_list);
      setSelectedItem(null);
      setPreview(null);
      setMessage("");
      setHighlighted(false);
      setRelatedLink("");
    }
  }, [data?.productshowcase_fetch_list]);

  useEffect(() => {
    if (data?.productshowcase_create_item) {
      setPreview(null);
      setMessage("");
      setImageFile(null);
      setHighlighted(false);
      setRelatedLink("");
      setMode(null);
    }
  }, [data?.productshowcase_create_item]);

  const handleSelectItem = (id) => {
    const item = existingData.find((item) => item.id === id);
    if (item) {
      setSelectedItem(item.id);
      setPreview(item.image);
      setMessage(item.message);
      setHighlighted(item.highlighted);
      setRelatedLink(item.related_link || "");
    }
  };

  const handleSave = async () => {
    if (!message) {
      alert("Please enter a message.");
      return;
    }

    const prep_payload = {
      message,
      highlighted,
      related_link: relatedLink,
    }
    const payload = imageFile ? {
      image: imageFile,
      ...prep_payload
    } : prep_payload;

    try {
      if (mode === "update" && selectedItem) {
        action.updateProductshowcaseItem(selectedItem, payload);
      } else if (mode === "create") {
        action.createProductshowcaseItem(payload);
      }
    } catch (error) {
      console.error("Error saving settings:", error);
    }
  };

  const handleDelete = async () => {
    if (!selectedItem) {
      alert("No item selected to delete.");
      return;
    }
    try {
      action.deleteProductshowcaseItem(selectedItem);
      setSelectedItem(null);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  return (
    <Segment>
      <Header as="h2">Product Showcase Settings</Header>
      {existingData && existingData.length > 0 && <div>
        <b>{existingData.length} items exists and {existingData.filter(i=>i.highlighted).length} items are enabled to showcase.</b>
      </div>}
      {existingData.length === 0 ? (
        <Button onClick={() => { setMode("create"); setHighlighted(true); }} primary>
          Create Item
        </Button>
      ) : (
        <>
          <Button onClick={() => { setMode("create"); setHighlighted(true); }} primary>
            Create Item
          </Button>
          <Button onClick={() => setMode("update")} secondary>
            Update Item
          </Button>
        </>
      )}
      {mode === "update" && existingData.length > 0 && (
        <div>
        <div>
        <br/>
        <b>Select an item to update:</b>
        </div>
        <Dropdown
          placeholder="Select an item"
          fluid
          selection
          options={existingData.map((item) => ({
            key: item.id,
            text: item.message,
            value: item.id,
          }))}
          onChange={(e, { value }) => handleSelectItem(value)}
          value={selectedItem}
        />
        </div>
      )}
      {mode && (
        <div>
        <br/>
          <Checkbox
            toggle
            label={highlighted ? "Enabled (Disable only if you don't want to display this item)" : "Disabled (Enable to show this item)"}
            checked={highlighted}
            onChange={() => setHighlighted(!highlighted)}
          />
          <div>
            <br />
            <Header as="h5">Upload Product Image</Header>
            <Input
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  setPreview(URL.createObjectURL(file));
                  setImageFile(file);
                }
              }}
            />
            {preview && <Image src={preview} size="medium" />}

            <Header as="h5">Flash Message</Header>
            <TextArea
              placeholder="Enter your message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            <Header as="h5">Related Link</Header>
            <Input
              type="url"
              placeholder="Enter related link (optional)"
              value={relatedLink}
              onChange={(e) => setRelatedLink(e.target.value)}
            />
          </div>
          <Button onClick={handleSave} primary>
            {mode === "update" ? "Update" : "Create"}
          </Button>
          {mode === "update" && selectedItem && (
            <Button onClick={handleDelete} color="red">
              Delete
            </Button>
          )}
        </div>
      )}
    </Segment>
  );
}

export function HighlightedShowcaseItems() {
  const [highlightedItems, setHighlightedItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const { initialize, data, action } = configureProductProductshowcase(
    utils,
    dispatch,
    state
  );

  useEffect(() => {
    action.fetchProductshowcaseList();
  }, []);

  useEffect(() => {
    if (data?.productshowcase_fetch_list) {
      const storedClosedItems = JSON.parse(localStorage.getItem("closedShowcaseItems")) || {};
      const currentTime = Date.now();
      const validClosedItems = Object.entries(storedClosedItems)
        .filter(([id, timestamp]) => currentTime - timestamp < 3600000)
        .reduce((obj, [id, timestamp]) => ({ ...obj, [id]: timestamp }), {});

      localStorage.setItem("closedShowcaseItems", JSON.stringify(validClosedItems));

      const filteredItems = data.productshowcase_fetch_list.filter(
        (item) => item.highlighted && !validClosedItems[item.id]
      );
      setHighlightedItems(filteredItems);
      if (filteredItems.length > 0) setOpen(true);
    }
  }, [data?.productshowcase_fetch_list]);

  const handleClose = (id) => {
    const storedClosedItems = JSON.parse(localStorage.getItem("closedShowcaseItems")) || {};
    storedClosedItems[id] = Date.now();
    localStorage.setItem("closedShowcaseItems", JSON.stringify(storedClosedItems));
    const remainingItems = highlightedItems.filter((item) => item.id !== id);
    setHighlightedItems(remainingItems);
    if (remainingItems.length === 0) setOpen(false);
  };

  if (highlightedItems.length === 0) return null;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % highlightedItems.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + highlightedItems.length) % highlightedItems.length);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Modal.Content>
        {highlightedItems.length > 0 && (
          <div className="highlighted-showcase-container">
            <button style={{position: 'absolute', zIndex: 1}} className="nav-button left" onClick={prevSlide}>◄</button>
            <div
              className="showcase-item"
              style={{ backgroundImage: `url(${highlightedItems[currentIndex].image})` }}
            >
              <button className="close-button" onClick={() => handleClose(highlightedItems[currentIndex].id)}>×</button>
              <Header as="h1" className="showcase-message">{highlightedItems[currentIndex].message}</Header>
              {highlightedItems[currentIndex].related_link && (
                <p style={{}}>
                  <a style={{color: 'black', padding: '10px 15px', backgroundColor: 'white', boxShadow: '0px 0px 4px black'}} href={highlightedItems[currentIndex].related_link} target="_blank" rel="noopener noreferrer">More Info</a>
                </p>
              )}
            </div>
            <button style={{position: 'absolute', zIndex: 1}} className="nav-button right" onClick={nextSlide}>►</button>
          </div>
        )}
      </Modal.Content>

      <style jsx>{`
        .highlighted-showcase-container {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 400px;
        }

        .showcase-item {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: white;
        }

        .nav-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: rgba(0, 0, 0, 0.5);
          color: white;
          border: none;
          padding: 10px;
          cursor: pointer;
          font-size: 24px;
        }

        .nav-button.left {
          left: 10px;
        }

        .nav-button.right {
          right: 10px;
        }

        .close-button {
          position: absolute;
          top: 10px;
          right: 10px;
          background: rgba(0, 0, 0, 0.7);
          color: white;
          border: none;
          font-size: 24px;
          cursor: pointer;
        }
      `}</style>
    </Modal>
  );
}
